.slider {
  padding: 0;
}
.eventVideoSlider {
  padding-bottom: 2%;
  video {
    width: 100%;
    /*opacity: 0.6;*/
  }
  img {
    width: 100%;
  }
  .dynamicDate {
    position: absolute !important;
    top: 53.2%;
    left: 6.5%;
      h5 {
        font-weight: 400;
        color: #ffffffc9;
        font-size: 38px;
        letter-spacing: 0.03rem;
    }
  }
  .videoText {
    position: absolute !important;
    top: 67%;
    left: 6.5%;
    h1 {
      height: auto;
      max-width: none;
      font-weight: 900;
      line-height: 70px;
      font-size: 3.5rem;
      color: #fff;
      margin-bottom: 0.4rem;
    }
    h5 {
      opacity: 0.8 !important;
      color: #fff;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 1.6;
      letter-spacing: 0.01em;
    }
    Button {
      margin-bottom: 40px;
      margin-top: 2%;
      cursor: pointer;
      text-transform: uppercase;
      vertical-align: middle;
      letter-spacing: 0.03rem;
      font-size: 18px;
      padding: 10px 60px;
      border-radius: 8px;
      background: #ffffff;
      border: 0;
      color: #000000;
      font-weight: 600;
    }
    Button:hover {
      color: #000000;
      background: #ffffff;
      border: 0 !important;
    }
    Button:disabled {
      background: #ffffffa6;
      color: #000000e3 !important      ;
    }
  }
}
.videoSliderForDesktop {
  display: block;
}
.videoSliderForMobile {
  display: none;
}

/* mobile  */
@media only screen and (max-width: 480px) and (min-width: 320px) {
  .videoSliderForDesktop {
    display: none;
  }
  .videoSliderForMobile {
    display: block;
  }
  .eventVideoSlider {
    padding-bottom: 9%;
    // img {
    //     width: 115px!important;
    // }
    .dynamicDate {
      position: absolute !important;
      top: 44.2%;
      left: 5.5%;
        h5 {
          font-weight: 400;
          color: #ffffff;
          font-size: 24px;
          letter-spacing: 0.03rem;
          line-height: 32px;
      }
    }
    .videoText {
      position: absolute !important;
      top: 59%;
      left: 6.5%;
      h1 {
        height: auto;
        max-width: none;
        font-weight: 900;
        line-height: 25px;
        font-size: 1.5rem;
        color: #fff;
        margin-bottom: 0.4rem;
      }
      h5 {
        font-size: 1.2rem;
      }
      Button {
        margin-top: -5px;
        font-size: 5vw;
        padding: 7px 25px;
        margin-left: -5px;
      }
    }
  }
}

/* Tab  */
@media (min-width: 768px) and (max-width: 1024px) {
  .eventVideoSlider {
    video {
      width: 100%;
      opacity: 0.6;
    }
    img {
      width: 100%;
      opacity: 0.6;
    }
    .videoText {
      position: absolute !important;
      top: 15%;
      left: 4%;
      h1 {
        height: auto;
        max-width: none;
        font-weight: 900;
        line-height: 70px;
        font-size: 3.5rem;
        color: #fff;
        margin-bottom: 0.4rem;
      }
      h5 {
        opacity: 0.8 !important;
        color: #fff;
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 1.6;
        letter-spacing: 0.01em;
      }
    }
  }
}
